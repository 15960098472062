
import { openModal } from '@ds/components/ModalBase'

import VideoModal from '../VideoModal'

/** Display a YouTube video in a modal after clicking with sending the video id as tracking value */
export default {
  components: {
    VideoModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modalName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    // @todo: remove when the DS modal changes the fact that
    // the close button is displayed only when there is a title
    emptyVideoTitle() {
      return ' '
    },
  },
  methods: {
    show() {
      openModal(this.modalName)
    },
  },
}
