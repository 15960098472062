
import isEmpty from 'lodash/isEmpty'

import { tw } from '@core/tailwind'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasTitle() {
      return !isEmpty(this.title)
    },
    hasSubtitle() {
      return !isEmpty(this.subtitle)
    },
    titleClass() {
      return this.hasSubtitle ? tw`mb-2 md:mb-0 md:mr-3` : ''
    },
  },
}
