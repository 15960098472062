// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C\\+9O3WsJUZrHLDK7wVPd\\+A\\=\\={aspect-ratio:16/9}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aspectRatio": "C+9O3WsJUZrHLDK7wVPd+A=="
};
module.exports = ___CSS_LOADER_EXPORT___;
