
import { RevModal } from '@ds/components/Modal'

import YoutubeEmbed from '../YoutubeEmbed'

export default {
  components: {
    RevModal,
    YoutubeEmbed,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
