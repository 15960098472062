
export default {
  props: {
    youtubeId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
