
import { RevButton } from '@ds/components/Button'
import isEmpty from 'lodash/isEmpty'

import { trackClick } from '@tracking/events'

import BlockTitle from '../private/BlockTitle'
import CTAVideo from '../private/CTAVideo'

export default {
  components: {
    RevButton,
    BlockTitle,
    CTAVideo,
  },
  props: {
    ctaLink: {
      type: Object,
      default: () => ({}),
    },
    ctaVideo: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tracking: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    hasCtaVideo() {
      return !isEmpty(this.ctaVideo)
    },
    hasCtaLink() {
      return !isEmpty(this.ctaLink)
    },
    getLinkTarget() {
      return this.ctaLink.link?.targetBlank ? '_blank' : false
    },
  },
  methods: {
    sendTrackingData() {
      trackClick({
        ...this.tracking,
        name: this.title,
      })
    },
    openModalWithVideo(openModal) {
      openModal()
      this.sendTrackingData()
    },
  },
}
